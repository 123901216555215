import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AudioPlayer = makeShortcode("AudioPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "dhamma---principles-or-phenomena",
      "style": {
        "position": "relative"
      }
    }}>{`Dhamma - Principles or Phenomena`}<a parentName="h1" {...{
        "href": "#dhamma---principles-or-phenomena",
        "aria-label": "dhamma   principles or phenomena permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`Similarly to `}<em parentName="p">{`citta`}</em>{`, we require at least two different sessions to begin exploring the notion of `}<em parentName="p">{`dhamma`}</em>{`, which means something like both principles (or teaching) and phenomena (each phenomenon of our lives being a lesson and each life itself its own greatest teacher).`}</p>
    <h2 {...{
      "id": "attraction--repulsion",
      "style": {
        "position": "relative"
      }
    }}>{`Attraction & Repulsion`}<a parentName="h2" {...{
        "href": "#attraction--repulsion",
        "aria-label": "attraction  repulsion permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/marlon_meditation_6.mp3" mdxType="AudioPlayer" />
    <h2 {...{
      "id": "loving-awareness",
      "style": {
        "position": "relative"
      }
    }}>{`Loving Awareness`}<a parentName="h2" {...{
        "href": "#loving-awareness",
        "aria-label": "loving awareness permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/meditation_with_marlon_7.mp3" mdxType="AudioPlayer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      